









































import {
  Component,
  Prop,
  Vue
} from 'vue-property-decorator'
import {
  errorMsg,
  getTranslations,
  hasPermission,
  successMsg
} from '@/utils'
import { transferUser } from '@/api/recommendations'

@Component({
  name: 'CollaboratorToColumn'
})

export default class extends Vue {
  @Prop({ default: false }) private isOutgoing!: boolean
  @Prop({ required: false }) private users!: any

  private getTranslations = getTranslations
  private hasPermission = hasPermission

  private async saveItem(row: any) {
    try {
      await transferUser(row)
      successMsg('actions.apiSuccessSave')
      this.$emit('reload-list')
    } catch (err) {
      errorMsg('api.serverError')
    }
  }
}
