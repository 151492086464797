

























































































































































































































import {
  Component,
  Prop,
  Vue, Watch
} from 'vue-property-decorator'
import {
  approveRecommendation, cancelRecommendation,
  deleteRecommendation,
  deleteRecommendationBulk,
  exportRecommendations,
  getRecommendations,
  getRecommendationsCount, rejectRecommendation
} from '@/api/recommendations'
import {
  addOfficeFilters, addUserFilters,
  createFilter, dateFilter, FilterOperator, FilterType,
  getSortItems, personalFilter, prepareListFilters, singleSelectFilter
} from '@/utils/filter'
import Pagination from '@/components/Pagination/index.vue'
import ListToolbar from '@/components/ListToolbar/index.vue'
import FiltersDrawer from '@/components/Filter/FiltersDrawer.vue'
import StatusColumn from '@/views/recommendation/components/StatusColumn.vue'
import CollaboratorToColumn from '@/views/recommendation/components/CollaboratorToColumn.vue'
import RejectionDialog from '@/components/RejectionDialog/index.vue'
import {
  debounce,
  parseTime,
  getTranslations,
  hasPermission,
  confirmDialog,
  successMsg,
  errorMsg,
  getSubText
} from '@/utils'
import { AppModule, DeviceType } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import { redirectToEditUrl } from '@/utils/recommendation'
import { getUsersLight } from '@/api/helpers'

@Component({
  name: 'recommendationList',
  components: {
    Pagination,
    ListToolbar,
    StatusColumn,
    FiltersDrawer,
    RejectionDialog,
    CollaboratorToColumn
  }
})

export default class extends Vue {
  @Prop({ default: false }) private isOutgoing!: boolean
  @Prop({ default: false }) private isProperty!: boolean

  private count = 0
  private total = 0
  private list = []
  private users = []
  private listLoading = true
  private showFilters = false
  private search = ''
  private selected = []
  private listQuery = createFilter()
  private debounceMethod = debounce(this.getList)
  private parseTime = parseTime
  private getTranslations = getTranslations
  private hasPermission = hasPermission
  private getSubText = getSubText
  private rejectRecDialog = false
  private recommendation:any = null
  private searchFields: any = [{ key: 'id' }]

  get title() {
    return this.$t('recommendationList.' + (this.isOutgoing ? 'outgoing' : 'incoming') + (this.isProperty ? 'Property' : 'Client'))
  }

  private filters: any = [
    singleSelectFilter({
      lowerCase: true,
      label: 'filters.status',
      key: 'status',
      transKey: 'rec_status',
      list: ['APPROVED', 'REJECTED', 'NEWLY_ADDED', 'CANCELED']
    })
  ]

  addOffice(val: string) {
    const { filter, search } = addOfficeFilters(val)
    this.filters.push(filter)
    this.searchFields.push(search)
  }

  addUser(val: string) {
    const { filter, search } = addUserFilters(val)
    this.filters.push(filter)
    this.searchFields.push(search)
  }

  addPersonalFilter(val: string) {
    this.filters.push(personalFilter({
      key: `user_id_${val}`,
      label: 'filters.personal'
    }))
  }

  created() {
    this.getQueryParams()
    this.getList()
    this.getAllUsers()
    this.addOffice(this.isOutgoing ? 'to' : 'from')
    this.addUser(this.isOutgoing ? 'to' : 'from')
    if (hasPermission(['LIST_OTHERS_RECOMMENDATIONS'])) {
      this.addUser(this.isOutgoing ? 'from' : 'to')
      this.addPersonalFilter(this.isOutgoing ? 'from' : 'to')
    }
  }

  private getQueryParams() {
    const query: any = this.$route.query || {}
    if (query.id) {
      this.search = query.id
    }
    this.filters.push(dateFilter({
      from: query.from ? new Date(Number(query.from)) : null,
      to: query.to ? new Date(Number(query.to)) : null
    }))
  }

  get dialogSize() {
    if (AppModule.device === DeviceType.Desktop) {
      return '30%'
    }
    return '80%'
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private async getAllUsers() {
    try {
      const listQuery = createFilter([{
        type: FilterType.field,
        key: 'officeId',
        value: UserModule.officeId,
        operator: FilterOperator.eq
      }])
      if (listQuery.query) {
        listQuery.query.pagination = {
          fetch_all: true
        }
      }
      const { data } = await getUsersLight(listQuery)
      this.users = data.collection
    } catch (err) {
      this.users = []
    }
  }

  private async deleteItem(id: string) {
    const [data] = await confirmDialog('actions.apiDelete')
    if (!data) return
    try {
      await deleteRecommendation({ id })
      await successMsg('actions.apiDeleteSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private handleSelectionChange(selected = []) {
    this.selected = selected
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private async deleteItemsBulk() {
    if (!this.selected.length) {
      return errorMsg('actions.selectItems')
    }
    const [data] = await confirmDialog('actions.apiDeleteBulk')
    if (!data) return
    try {
      await deleteRecommendationBulk(this.selected.map((item: any) => {
        return item.id
      }))
      await successMsg('actions.apiDeleteBulkSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private rejectRecommendationAction(id: string) {
    this.handleDialog({ id: id }, true)
  }

  private handleDialog(recommendation: null | object = null, dialog = false) {
    this.recommendation = recommendation
    this.rejectRecDialog = dialog
  }

  private isSuperAdmin(user: any) {
    return user.roles.some((role: any) => {
      return role.name === 'SUPER_ADMIN'
    })
  }

  private async acceptRecommendation(id: string) {
    const [valid] = await confirmDialog('collaborationList.accept')
    if (!valid) return
    try {
      await approveRecommendation({ id })
      await successMsg('actions.apiSuccessSave')
      this.handleDialog()
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private async cancelItem(id: string) {
    const [data] = await confirmDialog('collaborationDetail.apiCancel')
    if (!data) return
    try {
      await cancelRecommendation({ id })
      await successMsg('actions.apiDeleteSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private async reject() {
    try {
      await rejectRecommendation(this.recommendation)
      await successMsg('actions.apiSuccessSave')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private getClientTxt(client: any) {
    const items = [getTranslations(client)]
    if (client.main_phone) {
      items.push(`${this.$t('table.phone')}: ${client.main_phone}`)
    }
    if (client.secondary_phone) {
      items.push(`${this.$t('table.mobilePhone')}: ${client.secondary_phone}`)
    }
    return items.join('<br>')
  }

  private propertyColumnHtml(property: any) {
    const values = []

    if (!property) {
      return ''
    }

    if (property.available_for) {
      values.push(this.$t('propertyDetail.availableFor') + ': ' + this.$t('available_for.' + property.available_for))
    }

    if (property.category) {
      values.push(this.$t('propertyDetail.category') + ': ' + this.$t('property_category.' + property.category))
    }

    if (property.type) {
      values.push(this.$t('propertyDetail.property_type') + ': ' + this.$t('property_type.' + property.type))
    }

    if (property.state) {
      values.push(this.$t('property_state.title') + ': ' + this.$t('property_state.' + property.state))
    }

    if (property.floor) {
      values.push(this.$t('propertyDetail.floor') + ': ' + property.floor)
    }

    return values.join(', ')
  }

  private prepareFilters(filters: any) {
    prepareListFilters({
      listQuery: this.listQuery,
      withDefaultSort: true,
      search: this.search,
      searchFields: this.searchFields,
      filters: filters,
      callback: () => {
        this.listQuery.options = {}
        if (this.isProperty) {
          this.listQuery.options.properties_only = true
        } else {
          this.listQuery.options.clients_only = true
        }
        if (!this.isSuperAdmin(UserModule)) {
          if (this.isOutgoing) {
            this.listQuery.options.outgoing_only = true
          } else {
            this.listQuery.options.incoming_only = true
          }
        }
      }
    })
  }

  private async filtersChanged(filters: any) {
    try {
      this.prepareFilters(filters)
      const { data } = await getRecommendationsCount(this.listQuery)
      this.count = data || 0
    } catch (err) {}
  }

  private submitFilters(filters: any) {
    this.filters = filters
    this.restartPaging()
    this.getList()
  }

  private redirectToEdit(row: any) {
    return this.$router.push(redirectToEditUrl(row))
  }

  private handleCommand(command: string) {
    switch (command) {
      case 'download': {
        exportRecommendations(this.selected)
        break
      }
      case 'delete': {
        this.deleteItemsBulk()
        break
      }
      case 'filters': {
        this.count = this.total
        this.showFilters = true
        break
      }
    }
  }

  private restartPaging() {
    if (this.listQuery.query?.pagination) {
      this.listQuery.query.pagination.page = 1
    }
  }

  private async getList() {
    this.listLoading = true
    try {
      this.prepareFilters(this.filters)
      const { data } = await getRecommendations(this.listQuery)
      this.list = data.collection || []
      this.total = data.pagination.total_items || 0
      this.count = data.pagination.total_items || 0
    } catch (err) {}
    this.listLoading = false
  }

  @Watch('search')
  onSearchChanged(search: string) {
    this.search = search
    this.restartPaging()
    this.debounceMethod()
  }
}
